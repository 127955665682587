import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import logo from "../assets/logo.png";
import bgImg from "../assets/bgImg.png";
import Blocked from "../components/Blocked";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useEffect, useMemo, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpired from "../components/SubscriptionExpired";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  getRatio,
  allNewses,
  getSpread,
  findBanners,
  getSocketUrl,
  allCommodities,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${bgImg})`,
    width: "100vw",
    height: "100dvh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  mainContainer: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    padding: "1.5rem 2rem 1.5rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    height: "24%",
    display: "flex",
    justifyContent: "space-between",
    color: "#BF9E61",
  },
  logo: {
    height: "100%",
    width: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImg: {
    width: "90%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  carousel: {
    height: "100%",
    width: "32%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
  bannerImg: {
    width: "100%",
    height: "23vh",
    objectFit: "cover",
    borderRadius: "10px",
  },
  time: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ///////////////////////////
  commodityAndSport: {
    height: "63%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 25px 25px 25px",
    border: "2px dashed #9E875B",
    boxSizing: "border-box",
    borderRadius: "10px",
    backgroundColor: "#0000009E",
    color: "#FFFFFF",
  },
  table: {
    gridArea: "table",
    gridTemplateColumns: "1fr",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    boxSizing: "border-box",
    height: "100%",
    width: "68%",
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "16%",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    overflowX: "scroll",
    height: "84%",
  },
  tableRow1: {
    minHeight: "19%",
    display: "flex",
    backgroundColor: "#9E875B",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2.2rem",
  },
  spotRateBox: {
    height: "100%",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  spotRate: {
    height: "62.5%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
  },
  spotRateHeader: {
    display: "flex",
    alignItems: "center",
    height: "25%",
  },
  askOrBuyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  dolorBox: {
    color: "#D9AF1B",
    background: "#0C0D16",
    padding: "0px 7px 0px 7px",
    display: "inline-block",
    width: "fit-content",
    borderRadius: "4px",
  },
  spotRateBody: {
    height: "75%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "14px",
    flexDirection: "column",
    backgroundColor: "#FFFFFE",
  },
  spotRateRow: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "space-between",
  },
  spotRateGoldBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
  },
  spotRateSilverBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  spotRateGoldValueBox: {
    border: "1px solid #9E875B",
    borderRadius: "0 0 8px 8px",
    width: "10vw",
    height: "7.5vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.5s ease",
    justifyContent: "center",
  },
  spotRateSilverValueBox: {
    border: "1px solid #9E875B",
    borderRadius: "0 0 8px 8px",
    width: "7.5vw",
    height: "5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.5s ease",
  },

  highLowBox: {
    display: "flex",
    gap: "5px",
    width: "10vw",
    alignItems: "center",
    color: "#9E875B",
  },
  spotRateRowSilver: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "space-evenly",
  },
  spotRateSilver: {
    height: "32%",
    gridArea: "stat",
    backgroundColor: "#FFFFFF",
    padding: "14px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  //////////////////////////

  stat: {
    height: "100%",
    width: "30%",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "8vw",
    height: "auto",
  },

  footer: {
    color: "#fff",
    display: "flex",
    height: "9%",
    backgroundColor: "#FFFFFF",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#9E875B",
    width: "70%",
    clipPath: "polygon(0% 0, 100% 0, 93% 100%, 0 100%)",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatDay = () => {
    return moment(new Date()).format("dddd");
  };

  const buyersColor = "#30AC4B";
  const sellersColor = "#C42B2B";

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <div
      style={{
        height: "100vh",
      }}
      className={(openSubscriptionExpierd || openBlocked) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.header}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "20%",
              }}
            >
              <AccessTimeIcon
                sx={{
                  fontSize: "2.9vw",
                  color: "#000000",
                  background: "#BF9E61",
                  borderRadius: "50%",
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.9vw",
                  letterSpacing: "2px",
                }}
              >
                {moment(Date.now()).tz("Asia/Muscat").format("hh:mm A")}
              </Typography>
            </Box>

            <Box className={classes.logo}>
              <img className={classes.logoImg} src={logo} alt="amiceLogo" />
            </Box>
            <Box className={classes.time}>
              <CalendarMonthIcon
                sx={{
                  fontSize: "2.6vw",
                  color: "#000000",
                  background: "#BF9E61",
                  borderRadius: "50%",
                  p: 0.8,
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {formatDay()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontSize: "1.8vw",
                    display: "flex",
                    width: "100%",
                    mt: -1,
                    justifyContent: "space-between",
                  }}
                >
                  {formatDate()
                    .toUpperCase()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>{" "}
              </Box>
            </Box>
            <Box className={classes.carousel}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "10px",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    className={classes.bannerImg}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>
          </Box>
          <Box className={classes.commodityAndSport}>
            <Box className={classes.table}>
              <Box className={classes.tableHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 2,
                    // background: "white",
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                  }}
                >
                  BUY
                  <Typography
                    sx={{
                      fontSize: "0.9vw",
                      background: "#9E875B",
                      px: 0.5,
                      ml: 0.5,
                      borderRadius: "5px",
                    }}
                  >
                    OMR
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                  }}
                >
                  SELL
                  <Typography
                    sx={{
                      fontSize: "0.9vw",
                      background: "#9E875B",
                      px: 0.5,
                      ml: 0.5,
                      borderRadius: "5px",
                    }}
                  >
                    OMR
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box key={idx} className={classes.tableRow1}>
                      <Typography
                        style={{
                          fontSize: "1.5vw",
                          fontWeight: "bold",
                          backgroundColor: "#FFFFFE",
                          color: "#131312",
                          clipPath: "polygon(0% 0, 100% 0, 90% 100%, 0 100%)",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2vw",
                                  fontWeight: "bold",
                                }}
                              >
                                {word.toUpperCase()}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vw",
                                  marginTop: "0.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;{word}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        {commodity.unitLabel === "TTB"
                          ? ""
                          : commodity.displayPurity}
                      </Typography>
                      <Typography
                        style={{
                          padding: 0,
                          fontSize: "2vw",
                          fontWeight: "bold",

                          justifyContent: "center",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {commodity.unit}
                        {commodity.unitLabel}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "2vw",
                          fontWeight: "bold",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              0.386,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              0.386,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className={classes.spotRateBox}>
              <Box className={classes.spotRate}>
                <Box className={classes.spotRateHeader}>
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SPOT RATE
                  </Typography>
                </Box>
                <Box className={classes.spotRateBody}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "1.2vw",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#9E875B",
                        borderRadius: "5px",
                        py: 0.2,
                      }}
                    >
                      GOLD Oz
                    </Typography>
                  </Box>
                  <Box className={classes.spotRateRow}>
                    <Box className={classes.spotRateGoldBox}>
                      <Typography
                        sx={{
                          backgroundColor: "#9E875B",
                          width: "10vw",
                          px: 1.5,
                          fontSize: "1.1vw",
                          textAlign: "center",
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                      </Typography>
                      <Box
                        sx={{
                          background:
                            Number(gold?.cur?.bid).toFixed(2) <
                            Number(gold?.pre?.bid).toFixed(2)
                              ? "#C42B2B"
                              : Number(gold?.cur?.bid).toFixed(2) >
                                Number(gold?.pre?.bid).toFixed(2)
                              ? "#30AC4B"
                              : "#2F2E2C",
                          px: 1.5,
                        }}
                        className={classes.spotRateGoldValueBox}
                      >
                        <Typography
                          sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                        >
                          {(!gold?.cur?.bid
                            ? 0
                            : Number(spread.goldBidSpread) +
                              Number(gold?.cur?.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                      <Box className={classes.highLowBox} sx={{ px: 1.5 }}>
                        <Typography
                          style={{
                            fontSize: "1.1vw",
                            justifyContent: "center",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowDropDownIcon
                            sx={{
                              fontSize: "2vw",
                              color: "#D1172D",
                              padding: 0,
                            }}
                          />
                          {(!gold?.cur?.lowPrice
                            ? 0
                            : Number(spread.goldLowSpread) +
                              Number(gold?.cur?.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.spotRateGoldBox}>
                      <Typography
                        sx={{
                          backgroundColor: "#9E875B",
                          width: "10vw",
                          px: 1.5,
                          fontSize: "1.1vw",
                          textAlign: "center",
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        {displayBidOrBuy?.askOrSell?.toUpperCase()}
                      </Typography>
                      <Box
                        sx={{
                          background:
                            Number(gold?.cur?.ask).toFixed(2) <
                            Number(gold?.pre?.ask).toFixed(2)
                              ? "#C42B2B"
                              : Number(gold?.cur?.ask).toFixed(2) >
                                Number(gold?.pre?.ask).toFixed(2)
                              ? "#30AC4B"
                              : "#2F2E2C",
                          px: 1.5,
                        }}
                        className={classes.spotRateGoldValueBox}
                      >
                        <Typography
                          sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                        >
                          {(!gold?.cur?.ask
                            ? 0
                            : Number(spread.goldAskSpread) +
                              Number(gold?.cur?.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box className={classes.highLowBox} sx={{ px: 1.5 }}>
                        <Typography
                          style={{
                            fontSize: "1.1vw",
                            justifyContent: "center",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowDropUpIcon
                            sx={{
                              fontSize: "2vw",
                              color: "#2FB545",
                              padding: 0,
                            }}
                          />
                          {(!gold?.cur?.highPrice
                            ? 0
                            : Number(spread.goldHighSpread) +
                              Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.spotRateSilver}>
                <Typography
                  sx={{
                    fontSize: "1vw",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#9E875B",
                    borderRadius: "5px",
                    py: 0.2,
                    width: "100%",
                  }}
                >
                  SILVER Oz
                </Typography>

                <Box className={classes.spotRateRowSilver}>
                  <Box className={classes.spotRateGoldBox}>
                    <Typography
                      sx={{
                        backgroundColor: "#9E875B",
                        width: "7.5vw",
                        px: 1.5,
                        py: 0.2,
                        fontSize: "1vw",
                        textAlign: "center",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                    </Typography>
                    <Box
                      sx={{
                        background:
                          Number(silver?.cur?.bid).toFixed(2) <
                          Number(silver?.pre?.bid).toFixed(2)
                            ? "#C42B2B"
                            : Number(silver?.cur?.bid).toFixed(2) >
                              Number(silver?.pre?.bid).toFixed(2)
                            ? "#30AC4B"
                            : "#2F2E2C",

                        px: 1.5,
                      }}
                      className={classes.spotRateSilverValueBox}
                    >
                      <Typography sx={{ fontSize: "1.5vw" }}>
                        {(!silver?.cur?.bid
                          ? 0
                          : Number(spread.silverBidSpread) +
                            Number(silver?.cur?.bid)
                        ).toFixed(3)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.spotRateGoldBox}>
                    <Typography
                      sx={{
                        backgroundColor: "#9E875B",
                        width: "7.5vw",
                        px: 1.5,
                        py: 0.2,
                        fontSize: "1vw",
                        textAlign: "center",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      {displayBidOrBuy?.askOrSell?.toUpperCase()}
                    </Typography>
                    <Box
                      sx={{
                        background:
                          Number(silver?.cur?.ask).toFixed(2) <
                          Number(silver?.pre?.ask).toFixed(2)
                            ? "#C42B2B"
                            : Number(silver?.cur?.ask).toFixed(2) >
                              Number(silver?.pre?.ask).toFixed(2)
                            ? "#30AC4B"
                            : "#2F2E2C",
                        px: 1.5,
                      }}
                      className={classes.spotRateSilverValueBox}
                    >
                      <Typography sx={{ fontSize: "1.5vw" }}>
                        {(!silver?.cur?.ask
                          ? 0
                          : Number(spread.silverAskSpread) +
                            Number(silver?.cur?.ask)
                        ).toFixed(3)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ color: "#9E875B" }}>
                    <Typography
                      style={{
                        fontSize: "0.9vw",
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ArrowDropDownIcon
                        sx={{
                          fontSize: "2vw",
                          color: "#D1172D",
                          padding: 0,
                        }}
                      />
                      {(!silver?.cur?.lowPrice
                        ? 0
                        : Number(spread.silverLowSpread) +
                          Number(silver?.cur?.lowPrice)
                      ).toFixed(3)}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.9vw",
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ArrowDropUpIcon
                        sx={{
                          fontSize: "2vw",
                          color: "#2FB545",
                          padding: 0,
                        }}
                      />
                      {(!silver?.cur?.highPrice
                        ? 0
                        : Number(spread.silverHighSpread) +
                          Number(silver?.cur?.highPrice)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
              <marquee
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.5vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
            <Box className={classes.stat}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7vw",
                    fontWeight: "bold",
                    color: "#1C6DB9",
                  }}
                >
                  BUYERS
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7vw",
                    fontWeight: "bold",
                    color:
                      Number(ratio?.chgValue.replace("%", "")) >= 0
                        ? "#1C6DB9"
                        : "#D03933",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7vw",
                    fontWeight: "bold",
                    color: "#D63832",
                  }}
                >
                  SELLERS
                </Typography>
              </Box>
              <Box sx={{ width: "90%", px: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 7,
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7vw",
                    fontWeight: "bold",
                    color: "#1C6DB9",
                  }}
                >
                  {ratio?.Buyers}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImg}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                </Box>
                <Typography
                  sx={{
                    fontSize: "0.7vw",
                    fontWeight: "bold",
                    color: "#D63832",
                  }}
                >
                  {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
